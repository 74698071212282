@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:wght@200;400;500;600;700;800;900&display=swap);
  
*{
  margin:0;
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif;
}

body{
  background-color:#f0f2f5;
  overflow-y:scroll;
}
h1,h2,h3,h4,h5,h6{
  color: #1c1e21;
}
a{
  text-decoration: none;
  color: #1877f2;
}
#app{
  min-width: 360px;
  width: 100%;
}
input,button,select{
  outline:none;
}
input:focus, button:focus,select:focus{
  border-color:#1877f2 !important;
}
::-webkit-scrollbar{
  width:8px;
  background-color:transparent;
}
::-webkit-scrollbar-thumb{
  background-color:#a9b1bb;
  border-radius: 2px;
}
.MuiTouchRipple-root{
  color:#1877f2;
}

